<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="md" title="Approve/Reject Holiday">
      <b-row>
        <b-col cols="6">
          <p class="font-weight-bold h5 mb-0">{{ editedAbsence.absentable && editedAbsence.absentable.name }}</p>
          <p class="text-muted mb-0">{{ editedAbsence.absentable && editedAbsence.absentable.email }}</p>
        </b-col>
        <b-col cols="6">
          <p class="mb-0 text-right">{{ editedAbsence.working_days }} working days requested</p>
          <p class="mb-0 text-muted text-right">{{ moment(editedAbsence.start_date).format('DD/MM/YY') }} - {{ moment(editedAbsence.end_date).format('DD/MM/YY') }}</p>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="rejectHoliday" :disabled="approving || rejecting" :variant="(editedAbsence && editedAbsence.absentable_id) === (user && user.id) ? 'primary' : 'light'">
          <b-spinner class="mx-4" variant="primary" small v-if="rejecting"/>
          <span v-if="!rejecting">{{ (editedAbsence && editedAbsence.absentable_id) === (user && user.id) ? 'Withdraw Request' : 'Reject' }}</span>
        </b-button>
        <b-button @click.prevent="approveHoliday" :disabled="approving || rejecting" variant="primary" v-if="(editedAbsence && editedAbsence.absentable_id) !== (user && user.id)">
          <b-spinner class="mx-4" variant="light" small v-if="approving"/>
          <span v-if="!approving">Approve</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('absences', ['loading']),
    ...mapState('auth', ['user'])
  },
  data() {
    return {
      editedAbsence: {
        working_days: 0,
        absentable_id: 0,
        absentable_type: 0,
        approved_at: null,
        start_date: null,
        end_date: null,
        type: '',
      },
      approving: false,
      rejecting: false,
      errorMessage: '',
    };
  },
  props: {
    absence: Object,
    id: String,
  },
  methods: {
    ...mapActions('absences', ['fetch', 'fetchSingle', 'updateOrCreate']),
    moment,
    closeModal() {
      this.editedAbsence = {}
      this.$bvModal.hide(this.id);
    },
    async approveHoliday() {
      this.approving = true;
      await this.updateOrCreate({
        ...this.editedAbsence,
        approved_at: this.moment().toDate(),
      }).then(() => {
        this.approving = false;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        this.approving = false;
        this.errorMessage = `Could not approve holiday: ${err.statusText}`;
      });
    },
    async rejectHoliday() {
      this.rejecting = true;
      await this.updateOrCreate({
        ...this.editedAbsence,
        rejected_at: this.moment().toDate(),
      }).then(() => {
        this.rejecting = false;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        this.rejecting = false;
        this.errorMessage = `Could not reject holiday: ${err.statusText}`;
      });
    }
  },
  watch: {
    absence(value) {
      this.editedAbsence = {
        ...value,
      };
    }
  }
}
</script>

<style>

</style>
